<template>
  <div class="contact-page">
    <sub-banner>
      <template v-slot:text>
        <h1>Duo</h1>
      </template>
    </sub-banner>
    <section class="text-image" id="over-mij" style="flex-direction: row-reverse; padding-top: 100px; margin-top: -50px">
      <div class="container">
        <div class="text-side" data-aos="fade-right" data-aos-duration="1200">
          <h1 class="page-title" style="font-weight: 600">Hier moet een pakkende titel komen</h1>
          <span class="stripe"></span>
          <p>
            Wij zijn Willemien en Waisca. Samen maken we reguliere en/of christelijke muziek.
            Naast dat we allebei zingen, bespelen we verschillende instrumenten als gitaar,
            piano, percussie, mondharmonica en melodica. Op uw receptie, bruiloft of ceremonie
            zorgen wij voor akoestische achtergrondmuziek.
            <br>
            <br>
            Daarnaast kunnen we trouw- of uitvaartdiensten verzorgen. Dit kan begeleidend,
            maar kan ook optredend zijn.
            Als u op zoek bent naar een volledige band, die meer op de voorgrond aanwezig is
            en een feest kan verzorgen? Dat biedt ook mogelijkheden bij ons. Informeer
            vrijblijvend!
          </p>
        </div>
        <div class="img-side">
          <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/duo.webp"
               style="border-radius: 40px; max-height: 500px">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";

export default {
  name: "Verhalen",
  components: {
    SubBanner,
    TextImage
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;

.contact-title {
  @media (max-width: 700px) {
    font-size: 25px;
    line-height: 30px;
  }
}

</style>
