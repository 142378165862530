<template>
  <div class="contact-page">
    <sub-banner>
      <template v-slot:text>
        <h1>Contact</h1>
      </template>
    </sub-banner>
    <list-image>
      <template v-slot:image>
        <img src="/images/snazzy.png" alt="Muziek dichtbij willemien locatie" style="border: #122620 solid 2px">
      </template>
      <template v-slot:text>
        <h2 class="contact-title">Neem hier contact met mij op.</h2>
        <ul>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1"
                 x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path
        d="M467,76H45C20.137,76,0,96.262,0,121v270c0,24.885,20.285,45,45,45h422c24.655,0,45-20.03,45-45V121    C512,96.306,491.943,76,467,76z M460.698,106c-9.194,9.145-167.415,166.533-172.878,171.967c-8.5,8.5-19.8,13.18-31.82,13.18    s-23.32-4.681-31.848-13.208C220.478,274.284,64.003,118.634,51.302,106H460.698z M30,384.894V127.125L159.638,256.08L30,384.894z     M51.321,406l129.587-128.763l22.059,21.943c14.166,14.166,33,21.967,53.033,21.967c20.033,0,38.867-7.801,53.005-21.939    l22.087-21.971L460.679,406H51.321z M482,384.894L352.362,256.08L482,127.125V384.894z"/>
	</g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
              <g>
</g>
</svg>
            <a href="mailto:muziekdichtbij@outlook.com" target="_blank">muziekdichtbij@outlook.com</a></li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" height="512" viewBox="0 0 32 32" width="512"><g id="Layer_3" data-name="Layer 3"><path d="m30.035 22.594c-.053-.044-6.042-4.33-7.667-4.049-.781.138-1.228.67-2.123 1.737-.144.172-.491.583-.759.876a12.458 12.458 0 0 1 -1.651-.672 13.7 13.7 0 0 1 -6.321-6.321 12.458 12.458 0 0 1 -.672-1.651c.294-.269.706-.616.882-.764 1.061-.89 1.593-1.337 1.731-2.119.283-1.619-4.005-7.613-4.049-7.667a2.289 2.289 0 0 0 -1.706-.964c-1.738 0-6.7 6.436-6.7 7.521 0 .063.091 6.467 7.988 14.5 8.024 7.888 14.428 7.979 14.491 7.979 1.085 0 7.521-4.962 7.521-6.7a2.287 2.287 0 0 0 -.965-1.706zm-6.666 6.4c-.874-.072-6.248-.781-12.967-7.382-6.635-6.755-7.326-12.144-7.395-12.979a27.054 27.054 0 0 1 4.706-5.561c.04.04.093.1.161.178a35.391 35.391 0 0 1 3.574 6.063 11.886 11.886 0 0 1 -1.016.911 10.033 10.033 0 0 0 -1.512 1.422l-.243.34.072.411a11.418 11.418 0 0 0 .965 2.641 15.71 15.71 0 0 0 7.248 7.247 11.389 11.389 0 0 0 2.641.966l.411.072.34-.243a10.117 10.117 0 0 0 1.428-1.518c.313-.374.732-.873.89-1.014a35.163 35.163 0 0 1 6.078 3.578c.083.07.141.124.18.159a27.031 27.031 0 0 1 -5.561 4.707z"/></g></svg>
            <a href="tel:0651111431" target="_blank">06 255 870 47</a></li>
        </ul>

      </template>
    </list-image>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";
import ListImage from "@/components/ListImage";

export default {
  name: "Contact",
  components: {
    SubBanner,
    TextImage,
    ListImage
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;

.contact-title {
  @media (max-width: 700px) {
    font-size: 25px;
    line-height: 30px;
  }
}

</style>
