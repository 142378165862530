<template>
  <div class="contact-page">
    <sub-banner>
      <template v-slot:text>
        <h1>Verhalen</h1>
      </template>
    </sub-banner>
    <section style="margin-top: -50px">
      <div class="container">
        <p><strong>Gebeurtenissen Muziek Dichtbij<br/></strong><br/><em>Situatie De Hullen</em><br/>Een mevrouw was
          terminaal, kon alleen nog maar op bed liggen. Ik kwam bij haar<br/>aan bed, ik vertelde hoe ik heette en dat
          ik haar lievelingslied kwam zingen. Haar blik<br/>werd open en ze keek mij indringend aan. Vervolgens begon ik
          het lied: Veilig in<br/>Jezus armen, voor haar te zingen. Het was bijzonder en intiem. Ze zong zachtjes<br/>mee
          en aan het einde van het lied, gaf ze aan hoe mooi het was. De tranen stonden<br/>in haar ogen.</p>
        <br>
        <hr>
        <br>
        <p><em>Situatie De Hullen</em><br/>Als jij komt Willemien, is er even een lichtpuntje in de dag.<br/>Situatie
          Villa Expert Care<br/>De glimlach van een kind staat centraal bij het zingen voor de kinderen. Kinderen
          zijn<br/>of ernstig ziek of lichamelijk/ verstandelijk beperkt. Muziek zorgt ervoor dat we even<br/>contact
          kunnen maken. En soms ontstaat er een mooie glimlach op het gezicht.<br/></p>
        <br>
        <hr>
        <br>
        <p><em>Situatie Viatence Veldheem</em><br/>Een mevrouw van bijna 100 jaar was aanwezig bij een huiskamerconcert. Op het<br/>moment dat ik daar ruist langs de
          wolken wilde gaan zingen, gaf de begeleidster aan<br/>dat dit het lievelingslied was van die mevrouw, maar ze
          kon weinig meer horen. Ik<br/>ben dichtbij haar gaan zitten en samen hebben we het lied gezongen. Ze straalde
          en<br/>genoot van het moment.<br/><br/></p>
        <br>
        <hr>
        <br>
        <p><em>Situatie Viatence Veldheem</em><br/>Een mevrouw was terminaal. Ze
          kon alleen nog maar in bed liggen en had veel pijn.<br/>Ze was zichtbaar verzwakt. Ik heb een aantal liederen
          voor haar gezongen. Na 2<br/>liederen gaf ik aan dat het misschien beter was dat ik ging stoppen, ze wilde dat
          ik<br/>meer liederen voor haar speelde. Dit heb ik ook gedaan. Ik heb haar hand gepakt en<br/>samen contact
          gemaakt. Aan het einde pakte ze mijn hand en bedankte ze mij en<br/>vroeg ze aan de zuster of ze geld voor mij
          wilde pakken. Ze werd boos toen ik aangaf<br/>dat ik niets hoefde. Prachtig hoe zon klein moment zo groot kan
          worden.</p>
        <br>
        <hr>
        <br>
        <p><em>Situatie Viatence Turfhorst</em><br/>Op 2 afdelingen gespeeld waar mensen met dementie verblijven. Het
          was een mooie<br/>en bijzondere ochtenden. Mensen zongen mee en maakte contact. Prachtig hoe de<br/>oude
          liederen dan weer boven komen.</p>
        <br>
        <hr>
        <br>
        <p><em>Situatie Het Baken Hoenwaard</em><br/>Ik was bij de Hoenwaard, een locatie voor mensen met dementie
          verblijven. Ik vroeg<br/>aan de mensen die aanwezig waren wat de Johannes de Heer liederen met hen<br/>deden.
          Een mevrouw gaf aan: Ik weet niet meer zoveel, maar als ik deze liederen<br/>zing dan weet ik dat de Here God
          heel dichtbij mij is.</p>
        <br>
        <hr>
        <br>
        <p><em>Situatie het Baken locatie de Hullen</em><br/>Ik was bij een mevrouw van 93 jaar, haar dochter was ook
          aanwezig. Mevrouw had<br/>veel pijn, maar haalde veel kracht uit oude liederen. Ik ben naast haar gaan
          zitten<br/>met mijn gitaar en samen zijn we de oude liederen gaan zingen. Op een gegeven<br/>moment ben ik
          gestopt met zingen en zong ze alleen. De dochter van mevrouw was<br/>zeer ontroerd door dit moment.</p>
        <br>
        <hr>
        <br>
        <p><em>Situatie het Baken locatie de Hullen</em><br/>Ik was bij een mevrouw van 100 jaar. Ze kwam uit Amsterdam
          en had veel gereisd in<br/>haar leven. Ze was zwaar dementerend. Toen ik bij haar binnen kwam zat ze met<br/>haar
          knuffel op schoot, ze dacht dat dit haar baby was. Samen eerst hierover<br/>gesproken en vervolgens ben ik
          voor haar gaan zingen. Het was eerst even aftasten,<br/>maar op een gegeven moment begon ze mee te zingen.
          Prachtig hoe het weer<br/>boven kwam. Ze moest lachen en was zichtbaar aan het genieten. Ze heeft<br/>meerdere
          malen gezegd: Dit vind ik leuk :)</p>
        <br>
      </div>
    </section>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";

export default {
  name: "Verhalen",
  components: {
    SubBanner,
    TextImage
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;

.contact-title {
  @media (max-width: 700px) {
    font-size: 25px;
    line-height: 30px;
  }
}

</style>
