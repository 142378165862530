<template>
  <div class="contact-page">
    <sub-banner>
      <template v-slot:text>
        <h1>Over mij</h1>
      </template>
    </sub-banner>
    <section class="text-image" id="over-mij" style="flex-direction: row-reverse; padding-top: 100px; margin-top: -50px">
      <div class="container">
        <div class="text-side" data-aos="fade-right" data-aos-duration="1200">
          <h1 class="page-title" style="font-weight: 600">Even mezelf voorstellen</h1>
          <span class="stripe"></span>
          <p>
            Mijn naam is Willemien van Gelder. Ik ben geboren in 1988. Samen met mijn man en
            2 dochters woon ik in Oosterwolde Gelderland. Van jongs af aan heb ik een passie
            voor muziek gehad, op mijn 9 de ging ik naar de muziekschool en heb ik Bugel leren
            spelen bij de fanfare. Op mijn 15 de ben ik begonnen met het leren spelen van gitaar,
            piano en percussie.
            <br>
            <br>
            Naast mijn passie voor muziek wil ik ook graag iets voor een
            ander betekenen. In 2015 ben ik afgestudeerd aan de Hogeschool van Zwolle (Viaa)
            als Maatschappelijk werker.
            <br>
            <br>
            Tijdens mijn optredens staat persoonlijk contact centraal. Als ik ergens kom maak ik
            altijd een praatje met de mensen en ga ik naast mensen zitten als ik muziek maak. Ik
            maak live muziek met mijn keyboard, gitaar, percussie en zang. Samen met de
            zorginstelling stem ik mijn repertoire af en zing ik liederen van toen en nu.
          </p>
        </div>
        <div class="img-side">
          <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/gray.webp"
               style="border-radius: 40px; max-height: 500px">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";

export default {
  name: "Verhalen",
  components: {
    SubBanner,
    TextImage
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;

.contact-title {
  @media (max-width: 700px) {
    font-size: 25px;
    line-height: 30px;
  }
}

</style>
