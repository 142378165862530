import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import contact from "@/views/Contact";
import Verhalen from "@/views/Verhalen";
import About from "@/views/About";
import Duo from "@/views/Duo";
import GrootHuiskamerconcert from "@/views/Groothuiskamerconcert";
import KleinHuiskamerconcert from "@/views/KleinHuiskamerconcert";

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: HomeView,
    meta: {
      title: "Home | Muziek Dichtbij"
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    props: true,
    component: contact,
    meta: {
      title: "Contact | Muziek Dichtbij"
    }
  },
  {
    path: '/duo',
    name: 'Duo',
    props: true,
    component: Duo,
    meta: {
      title: "Duo | Muziek Dichtbij"
    }
  },
  {
    path: '/groot-huiskamerconcert',
    name: 'GrootHuiskamerconcert',
    props: true,
      component: GrootHuiskamerconcert,
    meta: {
      title: "Groot Huiskamerconcert | Muziek Dichtbij"
    }
  },
  {
    path: '/klein-huiskamerconcert',
    name: 'KleinHuiskamerconcert',
    props: true,
    component: KleinHuiskamerconcert,
    meta: {
      title: "Klein Huiskamerconcert | Muziek Dichtbij"
    }
  },
  {
    path: '/verhalen',
    name: 'Verhalen',
    props: true,
    component: Verhalen,
    meta: {
      title: "Verhalen | Muziek Dichtbij"
    }
  },
  {
    path: '/over-mij',
    name: 'About',
    props: true,
    component: About,
    meta: {
      title: "Over mij | Muziek Dichtbij"
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
})

export default router
