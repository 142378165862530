<template>
  <div class="contact-page">
    <sub-banner>
      <template v-slot:text>
        <h1>Klein huiskamerconcert / 1 op 1</h1>
      </template>
    </sub-banner>
    <section id="over-mij" class="text-image"
             style="flex-direction: row-reverse; padding-top: 100px; margin-top: -50px">
      <div class="container">
        <div class="text-side" data-aos="fade-right" data-aos-duration="1200">
          <h1 class="page-title" style="font-weight: 600">Hier moet een pakkende titel komen</h1>
          <span class="stripe"></span>
          <p>
            In veel zorginstellingen zijn er kleine huiskamers aanwezig. Als ik een
            huiskamerconcert geef in een kleine huiskamer staat persoonlijk contact centraal en
            zal ik dichtbij komen met de muziek. Het kan ook voorkomen dat mensen niet meer
            in de huiskamers aanwezig kunnen zijn en op de kamer verblijven. Dan kan ik aan
            bed muziek maken.
          </p>
        </div>
        <div class="img-side">
          <img alt="Willemien van Gelder Muziek Dichtbij" src="/images/4.webp"
               style="border-radius: 40px; max-height: 500px">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";

export default {
  name: "GrootHuiskamerconcert",
  components: {
    SubBanner,
    TextImage
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;

.contact-title {
  @media (max-width: 700px) {
    font-size: 25px;
    line-height: 30px;
  }
}

</style>
